<template>
  <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
      <div style="position: absolute; top: 40%; left: 50%; text-align: center;">
          <div class="spinner-border text-secondary" role="status"></div>
          <div> 加载中... </div>
      </div>
    </div>
  </div>
  <NavBar @usersearch="UserSearch"></NavBar>
  <span style="position: absolute; top: 25%; left: 2px;" id="dragBlock">
    <span style="position: relative;" @mouseleave="request_disappear" @mousemove="request_display">
      <img src="../assets/好友请求消息.png" alt="好友请求消息" style="position: relative; width: 25px;" @click="kkDrag">
      <span v-if="!(request_friends.length == 0 && now_pages == 1)" class="position-absolute translate-middle p-2 bg-danger border border-light rounded-circle kkbadge"></span>
      <div style="display: none; position: absolute; left: 26px; top: -14px;" id="requestBlock">
        <div class="kkf">
          <div v-for="friend in request_friends" :key="friend.id" id="friendsShowDiv">
            <div class="kkli">
              <img :src="friend.friend_photo" alt="" style="float: left; height: 40px; width: 40px;">
              <div style="display: inline-block; margin-left: 10px; margin-bottom: 15px;">
                <div style="font-weight: bold;"> {{ friend.friend_uname }}</div>
                <div style="font-size: 13px;"> {{ friend.friend_description }}</div>
              </div>
              <img src="../assets/不同意.png" alt="不同意" style="float: right; width: 20px; margin-top: 20px;" @click="refuse_friend(friend)">
              <img src="../assets/同意.png" alt="同意" style="float: right; width: 20px; margin-top: 20px; margin-right: 15px;" @click="permit_friend(friend)">
            </div>

          </div>
          <div v-if="request_friends.length == 0 && now_pages == 1">
              <div class="kk3"> 暂无好友添加请求~ </div>
          </div>
          <div v-if="request_friends.length == 0 && now_pages > 1">
              <div class="kk3"> 到底啦~ </div>
          </div>
          <div v-if="request_friends.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
              <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
              <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
              <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
          </div>
        </div>
      </div>
    </span>
  </span>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8 kkcol8">
      <div v-for="post in friend_posts" :key="post.id" style="margin-top: 10px; border-bottom: 1px solid #d4d6dc;">
        <div style="margin-top: 10px;">
          <img :src="post.photo" alt="" style="width: 40px;">
          <div style="display: inline-block; margin: 0 15px; font-size: 14px;">
            <span style="font-weight: bold; color: #576b95;">{{ post.uname }}</span>
            <span style="margin: 0 5px;">({{ post.description }})</span>
            <span></span>
            <span style="font-size: 13px; color: rgb(112, 110, 107); font-size: 12px;"> {{ post.create_time }}发布了帖子，快来看看吧~ </span>
          </div>
          <img src="../assets/评论.png" alt="" style="width: 20px; float: right; margin-right: 20px; margin-top: 5px;" @click="toPostDetail(post.id)">
          <img @click="give_praise(post)" v-if="!post.is_give_praise" src="../assets/点赞.png" alt="" style="width: 20px; float: right; margin-right: 15px; margin-top: 5px;">
          <img @click="cancel_praise(post)" v-else src="../assets/点赞_块.png" alt="取消点赞" style="width: 20px; float: right; margin-right: 15px; margin-top: 5px;">
        </div>
        <div class="kk10" style="font-size: 18px; font-weight: bold; margin-top: 5px; margin-bottom: 0px; background-color: white;"> {{ post.title }} </div>
      </div>
      <div v-if="friend_posts.length == 0 && post_pages == 1">
        <div class="kk3" style="margin: 10px 0;"> 暂无好友动态~ </div>
      </div>
      <div v-if="friend_posts.length == 0 && post_pages > 1">
         <div class="kk3" style="margin: 10px 0;"> 到底啦~ </div>
      </div>
      <div v-if="friend_posts.length != 0 || post_pages != 1" style="float: right; margin-right: 10px;">
        <img @click="post_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
        <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
        <img @click="post_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
        <div style="margin-bottom: 10px;"></div>
      </div>
    </div>
  </div>
  <span style="display: none; position: absolute; right: 1%; top: 12%;" id="SearchUsersBlock">
    <img @click="user_disappear" src="../assets/删除.png" alt="关闭" style="float: right; width: 18px;">
    <div class="kkf">
      <div v-for="user in search_users" :key="user.id" id="UsersShowDiv">
        <div class="kkli" :id="'kkli' + user.id">
          <img :src="user.friend_photo" alt="" style="float: left; height: 40px; width: 40px;">
          <div style="display: inline-block; margin-left: 10px; margin-bottom: 15px;">
            <span style="font-weight: bold;"> {{ user.friend_uname }}</span>
            <img @click="add_friend_request(user.id)" src="../assets/添加好友.png" alt="添加好友" style="width: 16px; margin-top: -5px; margin-left: 3px;">
            <div style="font-size: 13px;"> {{ user.friend_description }}</div>
          </div>
        </div>

      </div>
      <div v-if="search_users.length == 0 && search_pages == 1">
          <div class="kk3"> 暂无用户~ </div>
      </div>
      <div v-if="search_users.length == 0 && search_pages > 1">
          <div class="kk3"> 到底啦~ </div>
      </div>
      <div v-if="search_users.length != 0 || search_pages != 1" style="float: right; margin-right: 10px;">
          <img @click="search_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
          <span style="font-size: 12px;">&nbsp; {{ search_pages }} &nbsp;</span>
          <img @click="search_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
      </div>
    </div>
  </span>

  <!-- Button trigger modal -->
  <button id="request_errmsg_trigger" style="display: none;" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addFriendRequsetModal">
    Launch demo modal
  </button>

  <!-- 添加好友 Modal -->
  <div class="modal fade" id="addFriendRequsetModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">消息</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div id="addrequest_errmsg"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">确定</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavBar from "../components/NavBar.vue";
import {onMounted} from 'vue';
import { useStore } from 'vuex';
import router from '@/router/index';
import { ref } from 'vue';
import store from '@/store/user';
import $ from 'jquery';


export default {
  name: 'MySpace',
  components: {
    NavBar,

  },

  setup() {
    
    // Loading...
    const Loading = (type) => {
      let LoadingBlock = document.querySelector('#LoadingBlock');
      if(type == 1) {
        LoadingBlock.style.display = '';
      }
      else {
        LoadingBlock.style.display = 'none';
      }
    }

    const info_store = useStore();
    let request_err_msg = ref('');
    let request_friends = ref([]);
    let now_pages = ref(1);

    // 帖子发布时间显示

    const formatTime = (postTime) => {
      const now = new Date();
      const min60 = 60*60*1000;

      if(now.getTime() -  postTime.getTime() < min60) {
        let minutes = Math.floor((now.getTime() -  postTime.getTime()) / (1000 * 60));
        if(minutes == 0) return '刚刚'; 
        else return minutes + '分钟前';
      }

      let nowYear = now.getFullYear();
      let chatYear = postTime.getFullYear();
      let nowMonth = now.getMonth() + 1;
      let chatMonth = postTime.getMonth() + 1;
      let nowDate = now.getDate();
      let chatDate = postTime.getDate();
      let nowDay = now.getDay();
      let chatDay = postTime.getDay();
      // let nowHours = now.getHours();
      let chatHours = postTime.getHours();
      // let nowMinutes = now.getMinutes();
      let chatMinutes = postTime.getMinutes();

      let day = ['日','一','二','三','四','五','六'];
      let outHours = chatHours;
      if(chatHours < 10) outHours = '0' + outHours;
      let outMinutes = chatMinutes;
      if(chatMinutes < 10) outMinutes = '0' + outMinutes;

      // 往年消息
      if(nowYear != chatYear) return chatYear + '年' + chatMonth + '月' + chatDate + '日 ' + outHours + ':' + chatMinutes;
      // 本年往月消息
      if(nowMonth != chatMonth) return chatMonth + '月' + chatDate + '日 ' + outHours + ':' + outMinutes;
      // 昨天、前天消息
      if(nowDate - chatDate == 1) return '昨天' + outHours + ':' + outMinutes;
      if(nowDate - chatDate == 2) return '前天' + outHours + ':' + outMinutes;
      // 本周消息
      if(nowDay > chatDay && nowDate - chatDate <= 6 && nowDate - chatDate > 0) return '周' + day[chatDay] + outHours + ':' + outMinutes;
      // 当日消息
      if(nowDate == chatDate) {
        let hours = Math.floor((now.getTime() -  postTime.getTime()) / (1000 * 60 * 60));
        return hours + '小时前';
      }

      return chatMonth + '月' + chatDate + '日 ' + outHours + ':' + outMinutes;

    }

    // 添加好友请求翻页
    const last_pages = () => {
      if(now_pages.value <= 1) return;
      now_pages.value = now_pages.value - 1;
      refresh_friends_request();
    }

    const next_pages = () => {
      if(request_friends.value.length == 0) return;
      now_pages.value = now_pages.value + 1;
      refresh_friends_request();
    }

    const refresh_friends_request = () => {
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/requests/addfriends/",
        type: "post",
        data: {
          pages: now_pages.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          request_friends.value = resp;
        },
      });
    }

    // 同意添加好友
    const permit_friend = (friend) => {
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/requests/permit/",
        type: "post",
        data: {
          id: friend.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          refresh_friends_request();
        },
      });
    };

    // 拒绝添加好友
    const refuse_friend = (friend) => {
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/requests/refuse/",
        type: "post",
        data: {
          id: friend.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          refresh_friends_request();
        },
      });
    };

    let friend_posts = ref([]);
    let post_pages = ref(1);

    // 获取好友动态
    const refresh_friends_posts = () => {
      Loading(1);
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/post/friendsposts/",
        type: "get",
        data: {
          pages: post_pages.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          friend_posts.value = resp;
          // 格式化时间显示
          for(let i=0;i<friend_posts.value.length;i++) friend_posts.value[i].create_time = formatTime(new Date(friend_posts.value[i].create_time)) + ' ';
          Loading(2);
        },
      });
    };
    
    // 好友动态翻页
    const post_last_pages = () => {
      if(post_pages.value <= 1) return;
      post_pages.value = post_pages.value - 1;
      refresh_friends_posts();
    }

    const post_next_pages = () => {
      if(friend_posts.value.length == 0) return;
      post_pages.value = post_pages.value + 1;
      refresh_friends_posts();
    }

    // 跳转帖子详情页
    const toPostDetail = (post_id) => {
      window.open('/detail/' + post_id, '_blank');
      // router.push({
      //   name: 'PostDetailBlock',
      //   params: {
      //     post_id: post_id,
      //   },
      // });
    }

    // 用户搜索
    let search_pages = ref(1);
    let search_users = ref([]);
    let search_message = ref('');
    const UserSearch = (message) => {
      if(search_message.value != message) search_pages.value = 1;
      search_message.value = message;
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/user/search/",
        type: "get",
        data: {
          pages: search_pages.value,
          message: search_message.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          search_users.value = resp;
          let SearchUsersBlock = document.querySelector('#SearchUsersBlock');
          SearchUsersBlock.style.display = '';
        },
      });
    }

    // 用户搜索翻页
    const search_last_pages = () => {
      if(search_pages.value <= 1) return;
      search_pages.value = search_pages.value - 1;
      UserSearch(search_message.value);
    }

    const search_next_pages = () => {
      if(search_users.value.length == 0) return;
      search_pages.value = search_pages.value + 1;
      UserSearch(search_message.value);
    }


    // 好友申请拖拽
    let is_drag = ref(0);
    const kkDrag = () => {
      is_drag.value = !is_drag.value;
    }

    onMounted(() => {
      document.addEventListener('mousemove', (e) => {
        if(!is_drag.value) return;
        request_disappear();
        let dragBlock = document.querySelector('#dragBlock');
        dragBlock.style.top = e.pageY - 12 + 'px';
        dragBlock.style.left = e.pageX - 12 + 'px';
      });
      refresh_friends_request();
      refresh_friends_posts();
    });

    // 好友申请显示
    const request_disappear = () => {
      let requestBlock = document.querySelector('#requestBlock');
      requestBlock.style.display = 'none';
    };

    const request_display = () => {
      let requestBlock = document.querySelector('#requestBlock');
      requestBlock.style.display = '';
    };

    // 好友动态点赞
    const give_praise = (post) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/post/givePraise/",
        type: "post",
        data: {
          post_id: post.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          post.is_give_praise = true;
          post.praises_num = post.praises_num + 1;
        },
        error() {
        }
      });
    }

    const cancel_praise = (post) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/post/cancelPraise/",
        type: "post",
        data: {
          post_id: post.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          post.is_give_praise = false;
          post.praises_num = post.praises_num - 1;
        },
        error() {
        }
      });
    }

    // 申请添加好友
    const add_friend_request = (user_id) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      let request_errmsg_trigger = document.querySelector('#request_errmsg_trigger');
      let addrequest_errmsg = document.querySelector('#addrequest_errmsg');
      $.ajax({
        url: "https://chat.kkbpro.com/api/user/friend/addrequest/",
        type: "post",
        data: {
          id: user_id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          if(resp.err_msg == 'success') addrequest_errmsg.innerText = '消息已发送，等待对方同意~';
          else addrequest_errmsg.innerText = resp.err_msg;
          request_errmsg_trigger.click();
        },
        error(resp) {
          addrequest_errmsg.innerText = resp.err_msg;
          request_errmsg_trigger.click();
        }
      });
    }

    // 用户搜索显示
    const user_disappear = () => {
      let SearchUsersBlock = document.querySelector('#SearchUsersBlock');
      SearchUsersBlock.style.display = 'none';
    }


    return {
      info_store,
      request_friends,
      now_pages,
      request_err_msg,
      last_pages,
      next_pages,
      refresh_friends_request,
      permit_friend,
      refuse_friend,
      friend_posts,
      post_pages,
      refresh_friends_posts,
      post_last_pages,
      post_next_pages,
      toPostDetail,
      UserSearch,
      kkDrag,
      request_disappear,
      request_display,
      give_praise,
      cancel_praise,
      search_pages,
      search_users,
      search_last_pages,
      search_next_pages,
      add_friend_request,
      user_disappear,
      formatTime,
      Loading,


    }
  }

}
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.kkf {
  float: left;
  margin: 20px 0;
  border-radius: 3px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#friendsShowDiv {
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: 3px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

#friendsShowDiv::-webkit-scrollbar {
    width: 3px;
}

#friendsShowDiv::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

#friendsShowDiv::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}

.kkli {
  width: 180px;
  margin-left: 1px;
  margin-right: 1px;
  padding: 10px 10px; 
  padding-bottom: 5px;
}


.kkli:hover {
  background-color: #d9d8d8;
}

.kkbadge {
  position: absolute;
  padding: 0.2rem!important;
  top: 2px;
  left: 23px;
}

.kkcol8 {
  background-color: #ffffff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kk3{
  text-align: center;
  color: rgb(254, 81, 81);
  font-size: 12px;
  min-width: 120px;
}

.kk10
{
  /* 正常换行 */
  white-space: pre;
  padding: 5px 5px;
  max-width: 100%;
  text-align: left;
  max-height: 100px;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: rgb(248, 248, 248);
}

#UsersShowDiv {
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: 3px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

#UsersShowDiv::-webkit-scrollbar {
    width: 3px;
}

#UsersShowDiv::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

#UsersShowDiv::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}


</style>
