<template>
    <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
        <div style="position: absolute; top: 40%; left: 50%; text-align: center;">
            <div class="spinner-border text-secondary" role="status"></div>
            <div> 加载中... </div>
        </div>
        </div>
    </div>
    <NavBar></NavBar>
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
            <ContentFiled>
                <div style="font-weight: bold; font-size: 25px; margin-bottom: 10px;"> {{ post.title }} </div>
                <div>
                    <div style="display: inline-block; float: left;"><img :src="post.photo" alt="" style="height: 50px;"></div>
                    <div style="display: inline-block; height: 50px; margin-left: 10px; text-align: left;">
                        <div class="kkname" style="display: inline-block; margin-top: 0px;"> {{ post.uname }} </div>
                        <img @click="add_friend_request(post.writer_id)" src="../assets/添加好友.png" alt="添加好友" style="width: 20px; margin-left: 5px; margin-bottom: 5px;">
                        <div style="margin-top: 0px"> {{ post.description }} </div>
                    </div>
                    <div style="float: right; margin-top: 20px;"> <span class="kk5" >更新于 {{ post.modify_time }} , 阅读 {{ post.read_num }} , 获赞 {{ post.praises_num }} </span> </div>
                </div>
                <div class="kk10">
                    {{ post.content }}
                </div>
            </ContentFiled>

            <ContentFiled style="margin-bottom: 20px;">
                <div class="kk1"> {{ post.comments_num }} 评论 </div>
                <div class="kk2">
                    <img :src="store.state.photo" alt="" style="height: 40px; margin-top: 10px; margin-right: 15px;">
                    <div class="form-floating kk3" style="display: inline-block;">
                        <textarea v-model="comment_msg" class="form-control" id="commentTextarea" style="height: 80px;"></textarea>
                        <label for="commentTextarea">评论</label>
                        <div class="kk7" @mouseover="emoji_display" @mouseleave="emoji_disappear">
                            <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                            <EmojiPicker :native="true" @select="onSelectEmoji" style="display: none;" id="EmojiPicker" class="kk8"/>
                        </div>
                        <button type="button" class="btn btn-outline-secondary btn-sm" style="float: right; margin-top: 15px;" @click="sendComment">发表评论</button>
                    </div>
                </div>
                <div style="height: 20px;"></div>
                <div v-for="comment in comments" :key="comment.id" style="margin-top: 15px; border-top: 1px solid #e0e0e0; padding: 10px 10px; border-radius: 3px; padding-bottom: 0;">
                    <div style="display: inline-block; float: left;"><img :src="comment.photo" alt="" style="height: 30px;margin-top: 10px; margin-right: 15px;"></div>
                    <div style="display: inline-block; margin-top: 7px;">
                        <span>
                            <span class="kkname">{{ comment.uname }} </span>
                            <div style="display: inline-block; font-size: 14px; margin-top: -2px; margin-left: 5px;"> ({{ comment.description }}) </div>
                            <img @click="add_friend_request(comment.user_id)" src="../assets/添加好友.png" alt="添加好友" style="width: 20px; margin-left: 5px; margin-bottom: 3px;">
                            <span class="kk5" style="margin-left: 20px; font-size: 13px;"> {{ comment.send_time }} </span>
                        </span>
                    </div>
                    <div class="kk11">
                        {{ comment.content }}
                    </div>

                </div>
                <div v-if="comments.length == 0 && now_pages == 1">
                    <div class="kkempty"> 暂无评论，快来抢沙发吧~ </div>
                </div>
                <div v-if="comments.length == 0 && now_pages > 1">
                    <div class="kkempty"> 暂无更多评论~ </div>
                </div>
                <div v-if="comments.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
                    <div style="margin-bottom: 20px;"></div>
                    <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 12px;">
                    <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
                    <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 12px;">
                </div>
                
            
            </ContentFiled>
        </div>
    </div>

    <!-- Button trigger modal -->
    <button id="request_errmsg_trigger" style="display: none;" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addFriendRequsetModal">
        Launch demo modal
    </button>

    <!-- 添加好友 Modal -->
    <div class="modal fade" id="addFriendRequsetModal" tabindex="-1">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">消息</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div id="addrequest_errmsg"></div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">确定</button>
            </div>
        </div>
        </div>
    </div>
  
</template>
  
<script>
import ContentFiled from '../components/ContentFiled.vue'
import NavBar from "../components/NavBar.vue";
import store from '@/store/user';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import router from '@/router/index';
import $ from 'jquery';
import { ref } from 'vue';

import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
    
    
    
export default {
    name: 'PostDetails',
    components: {
      ContentFiled,
      NavBar,
      EmojiPicker,
  
    },
  
    setup()
    {
        // Loading...
        const Loading = (type) => {
        let LoadingBlock = document.querySelector('#LoadingBlock');
        if(type == 1) {
            LoadingBlock.style.display = '';
        }
        else {
            LoadingBlock.style.display = 'none';
        }
        }

        onMounted(() => {
            getPostInfo();
            getPostComments();
        })

        // 登录处理
        const info_store = useStore();
        const jwt_token = localStorage.getItem("jwt_token");
        if(jwt_token) {
            info_store.commit('updateToken',jwt_token);
            info_store.dispatch("getInfo", {
                success() {

                },
                error() {

                }
            });
        }

        const route = useRoute();
        let post_id = ref(0);
        post_id.value = route.params.post_id;
        let post = ref({});
        let post_err_msg = ref('');


        // 获取帖子信息
        const getPostInfo = () => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/user/post/detail/",
                type: "get",
                data: {
                id: post_id.value,
                },
                success(resp) {
                    if(resp) {
                        post.value = resp;
                    }
                    else post_err_msg.value = '帖子不存在或已被删除~';
                },
            });
        }
  
      let comments = ref([]);
      let now_pages = ref(1);

      // 获取帖子评论
      const getPostComments = () => {
        Loading(1);
        $.ajax({
            url: "https://chat.kkbpro.com/api/user/post/comments/",
            type: "get",
            data: {
                id: post_id.value,
                pages: now_pages.value,
            },
            success(resp) {
                if(resp) {
                    comments.value = resp;
                }
                Loading(2);
            },
        });
      }

      let comment_msg = ref('');
      // 发表评论
      const sendComment = ()=> {
        if(!store.state.is_login) router.push({name: 'LoginIndex'});
        $.ajax({
          url: "https://chat.kkbpro.com/api/user/post/sendcomment/",
          type: "post",
          data: {
            id: post_id.value,
            message: comment_msg.value,
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
            if(resp.err_msg == 'success') {
                getPostComments();
                comment_msg.value = '';
                post.value.comments_num++;
            }
            else router.push({name: 'LoginIndex'});
          },
          error() {
            
          }
        });
      }

        // emoji处理
        const emoji_display = () => {
            let EmojiPicker = document.querySelector('#EmojiPicker');
            EmojiPicker.style.display = '';
        }

        const emoji_disappear = () => {
            let EmojiPicker = document.querySelector('#EmojiPicker');
            EmojiPicker.style.display = 'none';
        }

        const onSelectEmoji = (emoji) => {
            // 选择表情的回调函数
            comment_msg.value += emoji.i;
        }

        // 翻页
        const last_pages = () => {
            if(now_pages.value <= 1) return;
            now_pages.value = now_pages.value - 1;
            getPostComments();
        }

        const next_pages = () => {
            if(comments.value.length == 0) return;
            now_pages.value = now_pages.value + 1;
            getPostComments();
        }

        // 申请添加好友
        const add_friend_request = (id) => {
            if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
            let request_errmsg_trigger = document.querySelector('#request_errmsg_trigger');
            let addrequest_errmsg = document.querySelector('#addrequest_errmsg');
            $.ajax({
            url: "https://chat.kkbpro.com/api/user/friend/addrequest/",
            type: "post",
            data: {
                id: id,
            },
            headers: {
                Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
                if(resp.err_msg == 'success') addrequest_errmsg.innerText = '消息已发送，等待对方同意~';
                else addrequest_errmsg.innerText = resp.err_msg;
                request_errmsg_trigger.click();
            },
            error(resp) {
                addrequest_errmsg.innerText = resp.err_msg;
                request_errmsg_trigger.click();
            }
            });
        }


  
      return {
        post,
        post_err_msg,
        store,
        comments,
        comment_msg,
        sendComment,
        emoji_display,
        emoji_disappear,
        onSelectEmoji,
        now_pages,
        last_pages,
        next_pages,
        add_friend_request,
        Loading,

      }
    }
  
  }
</script>
    
<style scoped>
img:hover {
  cursor: pointer;
}

.kkname {
    display: inline-block;
    margin-top: 5px;
    font-weight: bold;
    font-size: 17px;
}

#commentTextarea::-webkit-scrollbar {
    width: 5px;
}

#commentTextarea::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

#commentTextarea::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}


.kk1
{
    font-size: 25px;
    margin-bottom: 10px;
}

.kk2
{
    display: flex;
    align-items: stretch;
}

.kk3
{
    flex-grow: 1;
}

.kk5
{
  font-size: 10px;
  text-align: left;
  color: rgb(168, 168, 168);
}

.kk7
{
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  text-align: left;
  margin-top: 5px;
}

.kk8
{
  position: absolute;
  top: -320px;
}

.kk10
{
    /* 正常换行 */
    white-space: pre-line;
    word-break: break-all;
    padding: 5px 5px;
    margin-top: 20px;
    max-width: 100%;
    text-align: left;
    max-height: 5000px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgb(248, 248, 248);
}

.kk11
{
    /* 正常换行 */
    white-space: pre-line;
    word-break: break-all;
    padding: 5px 5px;
    margin-top: 15px;
    max-width: 100%;
    text-align: left;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgb(248, 248, 248);
}

.kk10::-webkit-scrollbar {
    width: 5px;
}

.kk10::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.kk10::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}

.kk11::-webkit-scrollbar {
    width: 5px;
}

.kk11::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.kk11::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}

.kkempty {
    text-align: center;
    color: #bec4c9;
    font-size: 13px;
}
    
</style>