<template>
  <div id="loginDiv" v-if="login_show == true">
    <form @submit.prevent="login" id="form" method="post">
      <h1 id="loginMsg">kkChat</h1>
      <div class="err">{{ err_msg }}</div>
      <p class="kk1">用户名:<input v-model="uname" id="username" name="username" type="text"></p>

      <p class="kk1">密&nbsp;&nbsp;&nbsp;码:<input v-model="password" id="password" name="password" type="password"></p>

      <div id="subDiv">
        <input type="submit" class="button" value="登录">
        <input type="reset" class="button" value="重置">&nbsp;&nbsp;&nbsp;
        <router-link :to="{ name: 'RegisterIndex' }">没有账号？点击注册</router-link>
      </div>
    </form>
  </div>
</template>
  
<script>
import userstore from '../store/user';
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from '@/router/index';

export default {
  name: 'LoginView',
  components: {

  },
  setup() {
    const store = useStore();
    let uname = ref('');
    let password = ref('');
    let err_msg = ref('');
    let login_show = ref(false);
    const jwt_token = localStorage.getItem("jwt_token");
    if(jwt_token) {
        store.commit('updateToken',jwt_token);
        store.dispatch("getInfo", {
            success() {
              if(userstore.state.last_webpages != "")
              {
                let pageName = userstore.state.last_webpages;
                userstore.state.last_webpages = "";
                router.push({name: pageName});
              }

              else
              {
                // 跳转到world页面
                router.push({name: 'WorldBlockIndex'});
              }
            },
            error() {
              login_show.value = true;
              err_msg.value = "登录已过期~";
            }
            
          })
      }
      else login_show.value = true;

    const login = () => {
      err_msg.value = "";
      store.dispatch("login",{
        uname: uname.value,
        password: password.value,
        success() {
          store.dispatch("getInfo", {
            success() {
              if(userstore.state.last_webpages != "")
              {
                let pageName = userstore.state.last_webpages;
                userstore.state.last_webpages = "";
                router.push({name: pageName});
              }

              else
              {
                // 跳转到world页面
                router.push({name: 'WorldBlockIndex'});
              }
            },
            error() {
              err_msg.value = '账号已被封禁~';
            }
            
          })
          
        },
        error(resp) {
          if(resp.err_msg == '账号已被封禁~') err_msg.value = '账号已被封禁~';
          else err_msg.value = '用户名或密码错误~';
        }
      })
    }

    return {
      uname,
      password,
      err_msg,
      login_show,
      login,
    }


  }

}
</script>
  
<style scoped>
.err {
  color: rgb(254, 81, 81);
  text-align: center;
  font-size: 15px;
  height: 15px;
}


#loginDiv {
  width: 37%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin: 130px auto;
  background-color: rgba(75, 81, 95, 0.3);
  box-shadow: 7px 7px 17px rgba(52, 56, 66, 0.5);
  border-radius: 5px;
}

#name_trip {
  margin-left: 50px;
  color: red;
}

p {
  margin-top: 30px;
  margin-left: 20px;
  color: azure;
}

input {
  margin-left: 15px;
  border-radius: 5px;
  border-style: hidden;
  height: 30px;
  width: 140px;
  background-color: rgba(216, 191, 216, 0.5);
  outline: none;
  color: #f0edf3;
  padding-left: 10px;
}

#username {
  width: 200px;
}

#password {
  width: 200px;
}

.button {
  border-color: cornsilk;
  background-color: rgba(100, 149, 237, .7);
  color: aliceblue;
  border-style: hidden;
  border-radius: 5px;
  width: 100px;
  height: 31px;
  font-size: 16px;
}

#subDiv {
  text-align: center;
  margin-top: 30px;
}

#loginMsg {
  text-align: center;
  color: aliceblue;
}
</style>