<template>
    <div class="kkinfo"> kkChat | 
        <a href="https://beian.miit.gov.cn"> 辽ICP备2023008635号-1 </a>
    </div>
</template>

<script>
export default{
    name: 'WebInfo',
}
</script>

<style scoped>
.kkinfo 
{
    color: rgb(241, 241, 241);
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
}

</style>