<template>
    <div id="LoadingBlock" style="display: none;">
        <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
        <div style="position: absolute; top: 40%; left: 50%; text-align: center;">
            <div class="spinner-border text-secondary" role="status"></div>
            <div> 加载中... </div>
        </div>
        </div>
    </div>
    <NavBar></NavBar>
    <div class="row">
        <div class="col-1"></div>
        <div class="col-3 kkcol3">
            <div style="font-size: 13px; padding: 10px 0; margin-bottom: 10px; color: #4b6b78; border-bottom: 1px solid rgb(199, 199, 199);">
                <span> 管理员kk，欢迎您~  </span>
                <img @click="rootSearchUser" src="../assets/管理员.png" alt="管理员" style="float: right; width: 20px;">
                <input @input="rootSearchUser" v-model="search_msg" class="kks" type="text" placeholder="查找用户~" >
            </div>
            <div v-for="user in users" :key="user.id" id="kkrootUserShow">
              <div class="kkli" :style="user.id == select_user_id ? 'background-color: #c6c5c6;' : ''" @click="getDetail(user)" :id="'kkli' + user.id">
                <img :src="user.photo" alt="" style="float: left; height: 40px; width: 40px;">               
                <div style="display: inline-block; margin-left: 13px; margin-bottom: 15px;">
                  <div style="font-weight: bold;"> {{ user.uname }}</div>
                  <div style="font-size: 13px;"> {{ user.description }}</div>
                </div>
              </div>
            </div>


            <div v-if="users.length == 0 && search_now_pages == 1">
                <div class="kk3"> 暂无相关用户~ </div>
                <div style="margin-bottom: 5px;"></div>
            </div>
            <div v-if="users.length == 0 && search_now_pages > 1">
                <div class="kk3"> 到底啦~ </div>
            </div>
            <div v-if="users.length != 0 || search_now_pages != 1" style="float: right; margin-right: 10px;">
                <img @click="search_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
                <span style="font-size: 12px;">&nbsp; {{ search_now_pages }} &nbsp;</span>
                <img @click="search_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
                <div style="margin-bottom: 10px;"></div>
            </div>
        </div>
        <div class="col-7 kkcol7" v-for="select_user in select_users" :key="select_user.id">
            <div>
                <div class="kkset"> 用户信息 </div>
                <div class="kkitem">
                    <div style="display: inline-block; width: 25%;">
                        <span style="font-size: 14px;"> 用户id: </span>
                        <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.id }} </span>
                    </div>
                    <div style="display: inline-block; width: 25%;">
                        <span style="font-size: 14px;"> 好友数: </span>
                        <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.friends_num }} </span>
                    </div>
                    <div style="display: inline-block; width: 25%;">
                        <span style="font-size: 14px;"> 帖子数: </span>
                        <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.posts_num }} </span>
                    </div>
                    <div style="display: inline-block; width: 25%;">
                        <span style="font-size: 14px;"> 获赞数: </span>
                        <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.getPraises }} </span>
                    </div>
                </div>
                <div class="kkitem">
                <span style="width: 20%; font-size: 14px;"> 头像 </span>
                <img @click="dispaly_control1" src="../assets/云上传.png" alt="云上传" style="width: 20px; float: right;">
                <div style="display: none; width: 60%; margin-top: 5px;" id="modifyPhotoBlock">
                    <input class="form-control kkinput" type="file" id="formFile">
                    <span class="err" style="float: right;"> {{ modphoto_errmsg }} </span>
                    <button @click="modify_photo" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">上传</button>
                    <button @click="disappear_control1" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
                </div>
                </div>
                <div class="kkitem">
                <span style="width: 20%; font-size: 14px;"> 昵称 </span>
                <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.uname }} </span>
                <img @click="dispaly_control2" src="../assets/设置.png" alt="管理员修改" style="width: 20px; float: right;">
                <div style="display: none; width: 60%; margin-top: 5px;" id="modifyUnameBlock">
                    <input v-model="new_uname" type="text" class="form-control kkinput" placeholder="输入新昵称~">
                    <span class="err" style="float: right;"> {{ moduname_errmsg }} </span>
                    <button @click="modify_uname" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">修改</button>
                    <button @click="disappear_control2" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
                </div>
                </div>
                <div class="kkitem">
                <span style="width: 20%; font-size: 14px;"> 称号 </span>
                <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ select_user.description }} </span>
                <img @click="dispaly_control3" src="../assets/设置.png" alt="管理员修改" style="width: 20px; float: right;">
                <div style="display: none; width: 60%; margin-top: 5px;" id="modifyDescriptionBlock">
                    <input v-model="new_description" type="text" class="form-control kkinput" placeholder="输入新称号~">
                    <span class="err" style="float: right;"> {{ moddescription_errmsg }} </span>
                    <button @click="modify_description" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">修改</button>
                    <button @click="disappear_control3" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
                </div>
                </div>
                <div class="kkitem">
                <span style="width: 20%; font-size: 14px;"> 密码重置 </span>
                <img @click="dispaly_control4" src="../assets/管理员重置密码.png" alt="管理员重置密码" style="width: 20px; float: right;">
                <div style="display: none; width: 60%; margin-top: 5px;" id="modifyPasswordBlock">
                    <input v-model="new_password" type="password" class="form-control kkinput" placeholder="输入新密码~" style="display: inline-block; width: 45%;">
                    <div></div>
                    <span class="err" style="float: right; margin-right: 30px;"> {{ modpassword_errmsg }} </span>
                    <button @click="modify_password" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">重置</button>
                    <button @click="disappear_control4" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
                </div>
                </div>
            </div>
            <div style="margin-bottom: 20px;"></div>
            <div>
                <div class="kkset"> 用户关联第三方账号 </div>
                <div class="kkitem">
                <img src="../assets/微信.png" alt="微信" style="width: 20px; margin-right: 5px; margin-bottom: 2px;">
                <span style="width: 20%; font-size: 14px;"> 微信 </span>
                <img src="../assets/关联.png" alt="关联" style="width: 20px; float: right;">
                </div>
            </div>
            <div style="margin-bottom: 20px;"></div>
            <div>
                <div class="kkset"> 用户帖子 
                <img src="../assets/管理员添加.png" alt="管理员新建帖子" style="float: right; width: 20px; margin-top: -3px;" data-bs-toggle="modal" data-bs-target="#add_post">
                </div>
                <table class="table table-striped table-hover">
                <thead>
                    <tr style="font-size: 14px;">
                    <th>标题</th>
                    <th>发布时间</th>
                    <th>更新时间</th>
                    <th>获赞</th>
                    <th>操作 </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post in posts" :key="post.id">
                    <td>
                        <div style="word-break: break-all; font-weight: bold; color: black; max-width: 160px; text-align: center;">{{ post.title }}</div>
                    </td>
                    <td>
                        <div style="font-size: 13px; color: #5e5e5e;">{{ post.create_time }}</div>
                    </td>
                    <td>
                        <div style="font-size: 13px; color: #5e5e5e;">{{ post.modify_time }}</div>
                    </td>
                    <td>
                        <div style="font-size: 13px; color: #5e5e5e;">{{ post.praises_num }}</div>
                    </td>
                    <td>
                        <img src="../assets/修改帖子.png" alt="修改帖子" style="float: left; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#update_post_' + post.id">

                        <!-- Modal update_post-->
                        <div class="modal fade" :id="'update_post_' + post.id" tabindex="-1">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">修改用户帖子~</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="mb-3">
                                <label for="post_title" class="form-label">标题</label>
                                <input v-model="post.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                                </div>
                                <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                                <textarea v-model="post.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                                    placeholder="在此输入正文~"></textarea>
                                </div>
                                <div class="kk7" @mouseover="emoji_display(post.id)" @mouseleave="emoji_disappear(post.id)">
                                <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                                <EmojiPicker :native="true" @select="onSelectEmoji($event, post)" style="display: none;" :id="'EmojiPicker_' + post.id" class="kk12"/>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="err">{{ modify_post_errmsg }}</div>
                                <button type="button" class="btn btn-primary btn-sm" @click="update_post(post)">修改</button>
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        <!-- Modal -->
                        <img src="../assets/删除帖子.png" alt="删除帖子" style="float: right; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_confirm_' + post.id">

                        <!-- 删除帖子确认 Modal -->
                        <div class="modal fade" :id="'del_confirm_' + post.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">删除帖子~</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" style="text-align: left;">
                                确认删除该帖子吗？
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger btn-sm" @click="remove_post(post)">删除</button>
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
                <div v-if="posts.length == 0 && now_pages == 1" class="kk9"> 用户未发布任何帖子~ 
                <div style="margin-bottom: 20px;"></div>
                </div>
                <div v-if="posts.length == 0 && now_pages > 1" class="kk9"> 到底啦~ </div>
                <div v-if="posts.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
                <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
                <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
                <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
                <div style="margin-bottom: 10px;"></div>
                </div>
                <!-- Modal add_post-->
                <div class="modal fade" id="add_post" tabindex="-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">新建用户帖子~</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                        <label for="post_title" class="form-label">标题</label>
                        <input v-model="add_post_info.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                        </div>
                        <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                        <textarea v-model="add_post_info.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                            placeholder="在此输入正文~"></textarea>
                        </div>
                        <div class="kk7" @mouseover="emoji_display(0)" @mouseleave="emoji_disappear(0)">
                        <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                        <EmojiPicker :native="true" @select="onSelectEmoji($event, add_post_info)" style="display: none;" id="EmojiPicker_0" class="kk12"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="err">{{ add_post_info.err_msg }}</div>
                        <button type="button" class="btn btn-primary btn-sm" @click="add_post">发布</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                    </div>
                    </div>
                </div>
                </div>
                <!-- Modal -->
            </div>
            <div v-if="posts.length == 0 && now_pages == 1" style="margin-bottom: 20px;"></div>
            <div v-else style="margin-bottom: 60px;"></div>
            <div>
                <div class="kkset"> 用户好友 
                    <img @click="rootAddFriend" src="../assets/管理员添加.png" alt="管理员添加" style="float: right; width: 20px;">
                    <input v-model="add_friend_id" class="kks" type="text" placeholder="添加好友id~" >
                </div>
                
                <table class="table table-striped table-hover">
                    <thead>
                        <tr style="font-size: 14px;">
                        <th>好友id</th>
                        <th>好友昵称</th>
                        <th>好友称号</th>
                        <th>好友头像</th>
                        <th> 删除 </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="friend in friends" :key="friend.id">
                        <td>
                            <div style="font-weight: bold; color: black; text-align: center;">{{ friend.id }}</div>
                        </td>
                        <td>
                            <div style="font-size: 13px; color: #5e5e5e; word-break: break-all; max-width: 80px; text-align: right;">{{ friend.friend_uname }}</div>
                        </td>
                        <td>
                            <div style="font-size: 13px; color: #5e5e5e; word-break: break-all; max-width: 80px; text-align: right;">{{ friend.friend_description }}</div>
                        </td>
                        <td>
                            <img :src="friend.friend_photo" alt="" style="width: 40px; height: 40px;">
                        </td>
                        <td>
                            <!-- Modal -->
                            <img src="../assets/管理员删除.png" alt="管理员删除好友" style="width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_friend_confirm_' + friend.id">

                            <!-- 删除帖子确认 Modal -->
                            <div class="modal fade" :id="'del_friend_confirm_' + friend.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">用户删除好友~</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body" style="text-align: left;">
                                    确认删除用户的该好友吗？
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger btn-sm" @click="rootRemoveFriend(friend)">删除</button>
                                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="friends.length == 0 && friend_now_pages == 1" class="kk9"> 用户没有任何好友~ 
                    <div style="margin-bottom: 20px;"></div>
                </div>
                <div v-if="friends.length == 0 && friend_now_pages > 1" class="kk9"> 到底啦~ </div>
                <div v-if="friends.length != 0 || friend_now_pages != 1" style="float: right; margin-right: 10px;">
                    <img @click="friend_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
                    <span style="font-size: 12px;">&nbsp; {{ friend_now_pages }} &nbsp;</span>
                    <img @click="friend_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
                    <div style="margin-bottom: 20px;"></div>
                </div>
                <!-- Modal add_post-->
                <div class="modal fade" id="add_post" tabindex="-1">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">新建用户帖子~</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                            <label for="post_title" class="form-label">标题</label>
                            <input v-model="add_post_info.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                            </div>
                            <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                            <textarea v-model="add_post_info.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                                placeholder="在此输入正文~"></textarea>
                            </div>
                            <div class="kk7" @mouseover="emoji_display(0)" @mouseleave="emoji_disappear(0)">
                            <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                            <EmojiPicker :native="true" @select="onSelectEmoji($event, add_post_info)" style="display: none;" id="EmojiPicker_0" class="kk12"/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="err">{{ add_post_info.err_msg }}</div>
                            <button type="button" class="btn btn-primary btn-sm" @click="add_post">发布</button>
                            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
            <div v-if="friends.length == 0 && friend_now_pages == 1" style="margin-bottom: 20px;"></div>
            <div v-else style="margin-bottom: 60px;"></div>
            <div>
                <div class="kkset"> 用户权限
                    <span class="kk9" style="margin-left: 10px;"> {{ Authority_errmsg }} </span>
                    <img @click="rootModifyAuthorityInfo(0)" src="../assets/用户权限认证.png" alt="用户权限认证" style="float: right; width: 20px; margin-top: -3px;">
                    <span style="float: right; font-size: 12px; color: #9d9d9d; margin-right: 10px;"> 一键开启 </span>
                </div>
                <table class="table table-striped table-hover">
                    <thead>
                        <tr style="font-size: 14px;">
                            <th>用户id</th>
                            <th>登录权限
                                <img @click="rootModifyAuthorityInfo(1)" src="../assets/转换.png" alt="转换" style="width: 20px;">
                            </th>
                            <th>帖子权限
                                <img @click="rootModifyAuthorityInfo(2)" src="../assets/转换.png" alt="转换" style="width: 20px;">
                            </th>
                            <th>好友权限
                                <img @click="rootModifyAuthorityInfo(3)" src="../assets/转换.png" alt="转换" style="width: 20px;">
                            </th>
                            <th>聊天权限
                                <img @click="rootModifyAuthorityInfo(4)" src="../assets/转换.png" alt="转换" style="width: 20px;">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="authority in authorityInfo" :key="authority.id">
                            <td>
                                <div style="font-weight: bold; color: black; text-align: center;">{{ select_user.id }}</div>
                            </td>
                            <td>
                                <span v-if="authority.login >= 0" style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/拥有权限.png" alt="拥有权限" style="width: 20px;">
                                    已开启
                                </span>
                                <span v-else style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/修改密码.png" alt="无权限" style="width: 20px;">
                                    已关闭
                                </span>

                            </td>
                            <td>
                                <span v-if="authority.post >= 0" style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/拥有权限.png" alt="拥有权限" style="width: 20px;">
                                    已开启
                                </span>
                                <span v-else style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/修改密码.png" alt="无权限" style="width: 20px;">
                                    已关闭
                                </span>
                            </td>
                            <td>
                                <span v-if="authority.friend >= 0" style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/拥有权限.png" alt="拥有权限" style="width: 20px;">
                                    已开启
                                </span>
                                <span v-else style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/修改密码.png" alt="无权限" style="width: 20px;">
                                    已关闭
                                </span>
                            </td>
                            <td>
                                <span v-if="authority.chat >= 0" style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/拥有权限.png" alt="拥有权限" style="width: 20px;">
                                    已开启
                                </span>
                                <span v-else style="font-size: 13px; color: #5e5e5e;">
                                    <img src="../assets/修改密码.png" alt="无权限" style="width: 20px;">
                                    已关闭
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- Modal add_post-->
                <div class="modal fade" id="add_post" tabindex="-1">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">新建用户帖子~</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                            <label for="post_title" class="form-label">标题</label>
                            <input v-model="add_post_info.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                            </div>
                            <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                            <textarea v-model="add_post_info.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                                placeholder="在此输入正文~"></textarea>
                            </div>
                            <div class="kk7" @mouseover="emoji_display(0)" @mouseleave="emoji_disappear(0)">
                            <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                            <EmojiPicker :native="true" @select="onSelectEmoji($event, add_post_info)" style="display: none;" id="EmojiPicker_0" class="kk12"/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="err">{{ add_post_info.err_msg }}</div>
                            <button type="button" class="btn btn-primary btn-sm" @click="add_post">发布</button>
                            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>

    </div>

</template>

<script>
import NavBar from "../components/NavBar.vue";
// import { useRoute } from 'vue-router';
// import { onUpdated } from 'vue';
import store from '@/store/user';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import { ref,reactive } from 'vue';

import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';

export default {
    name: 'KKRootUserBlock',
    components: {
      NavBar,
      EmojiPicker,
  
    },

    setup()
    {

        // Loading...
        const Loading = (type) => {
        let LoadingBlock = document.querySelector('#LoadingBlock');
        if(type == 1) {
            LoadingBlock.style.display = '';
        }
        else {
            LoadingBlock.style.display = 'none';
        }
        }

        let search_now_pages = ref(1);
        let friend_now_pages = ref(1);

        // 用户信息
        let users = ref([]);
        let posts = ref([]);
        let friends = ref([]);
        let add_post_info = reactive({
            title: '',
            content: '',
            err_msg: '',
        });
        let modify_post_errmsg = ref('');
        let select_user_id = ref(0);
        let select_users = ref([]);

        // 管理员用户搜索
        let search_msg = ref('');
        const rootSearchUser = () => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/getusers/",
                type: "post",
                data: {
                pages: search_now_pages.value,
                message: search_msg.value,
                },
                headers: {
                Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    users.value = resp;
                },
                error() {
                }
            });
        }

        rootSearchUser();

        // 用户搜索翻页
        const search_last_pages = () => {
            if(search_now_pages.value <= 1) return;
            search_now_pages.value = search_now_pages.value - 1;
            rootSearchUser();
        }

        const search_next_pages = () => {
            if(users.value.length == 0) return;
            search_now_pages.value = search_now_pages.value + 1;
            rootSearchUser();
        }

        // 用户好友翻页
        const friend_last_pages = () => {
            if(friend_now_pages.value <= 1) return;
            friend_now_pages.value = friend_now_pages.value - 1;
            refresh_friends();
        }

        const friend_next_pages = () => {
            if(friends.value.length == 0) return;
            friend_now_pages.value = friend_now_pages.value + 1;
            refresh_friends();
        }

        // 获取用户权限
        let authorityInfo = ref([]);
        const refresh_authority = () => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/authority/getinfo/",
                type: "post",
                data: {
                    id: select_user_id.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    authorityInfo.value[0] = resp;
                },
            });
        }

        // 更改用户权限
        let Authority_errmsg = ref('');
        const rootModifyAuthorityInfo = (type) => {
            let loginAuthority = 0;
            if(authorityInfo.value[0].login == 0) loginAuthority = -1;
            let postAuthority = 0;
            if(authorityInfo.value[0].post == 0) postAuthority = -1;
            let friendAuthority = 0;
            if(authorityInfo.value[0].friend == 0) friendAuthority = -1;
            let chatAuthority = 0;
            if(authorityInfo.value[0].chat == 0) chatAuthority = -1;
            if(type == 1) {
                $.ajax({
                    url: "https://chat.kkbpro.com/api/root/kk/authority/login/",
                    type: "post",
                    data: {
                        id: select_user_id.value,
                        login: loginAuthority,
                    },
                    headers: {
                        Authorization: 'Bearer ' + store.state.token,
                    },
                    success(resp) {
                        if(resp.err_msg == 'success') {
                            Authority_errmsg.value = '';
                            // 刷新
                            refresh_authority();
                        }
                        else {
                            Authority_errmsg.value = resp.err_msg;
                        }
                    },
                });
            }
            else if(type == 2) {
                $.ajax({
                    url: "https://chat.kkbpro.com/api/root/kk/authority/post/",
                    type: "post",
                    data: {
                        id: select_user_id.value,
                        post: postAuthority,
                    },
                    headers: {
                        Authorization: 'Bearer ' + store.state.token,
                    },
                    success(resp) {
                        if(resp.err_msg == 'success') {
                            Authority_errmsg.value = '';
                            // 刷新
                            refresh_authority();
                        }
                        else {
                            Authority_errmsg.value = resp.err_msg;
                        }
                    },
                });
            }
            else if(type == 3) {
                $.ajax({
                    url: "https://chat.kkbpro.com/api/root/kk/authority/friend/",
                    type: "post",
                    data: {
                        id: select_user_id.value,
                        friend: friendAuthority,
                    },
                    headers: {
                        Authorization: 'Bearer ' + store.state.token,
                    },
                    success(resp) {
                        if(resp.err_msg == 'success') {
                            Authority_errmsg.value = '';
                            // 刷新
                            refresh_authority();
                        }
                        else {
                            Authority_errmsg.value = resp.err_msg;
                        }
                    },
                });
            }
            else if(type == 4) {
                $.ajax({
                    url: "https://chat.kkbpro.com/api/root/kk/authority/chat/",
                    type: "post",
                    data: {
                        id: select_user_id.value,
                        chat: chatAuthority,
                    },
                    headers: {
                        Authorization: 'Bearer ' + store.state.token,
                    },
                    success(resp) {
                        if(resp.err_msg == 'success') {
                            Authority_errmsg.value = '';
                            // 刷新
                            refresh_authority();
                        }
                        else {
                            Authority_errmsg.value = resp.err_msg;
                        }
                    },
                });
            }
            else {
                $.ajax({
                    url: "https://chat.kkbpro.com/api/root/kk/authority/all/open/",
                    type: "post",
                    data: {
                        id: select_user_id.value,
                    },
                    headers: {
                        Authorization: 'Bearer ' + store.state.token,
                    },
                    success(resp) {
                        if(resp.err_msg == 'success') {
                            Authority_errmsg.value = '';
                            // 刷新
                            refresh_authority();
                        }
                        else {
                            Authority_errmsg.value = resp.err_msg;
                        }
                    },
                });
            }
        }


        // 清空所有信息及错误提示
        const all_clear = () => {
            Authority_errmsg.value = '';
            friend_now_pages.value = 1;
            now_pages.value = 1;
            modify_post_errmsg.value = '';
            new_uname.value = '';
            moduname_errmsg.value = '';
            new_description.value = '';
            moddescription_errmsg.value = '';
            new_password.value = '';
            modpassword_errmsg.value = '';
            modphoto_errmsg.value = '';
        }

        // 获取用户详细信息
        const getDetail = (user) => {
            all_clear();
            select_users.value[0] = user;
            select_user_id.value = user.id;
            refresh_posts();
            refresh_friends();
            refresh_authority();
        }

        // emoji处理
        const emoji_display = (id) => {
            let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
            EmojiPicker.style.display = '';
        }

        const emoji_disappear = (id) => {
        let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
        EmojiPicker.style.display = 'none';
        }

        const onSelectEmoji = (emoji, post) => {
        // 选择表情的回调函数
        post.content += emoji.i;

        }

        let now_pages = ref(1);

        // 用户帖子翻页
        const last_pages = () => {
        if(now_pages.value <= 1) return;
        now_pages.value = now_pages.value - 1;
        refresh_posts();
        }

        const next_pages = () => {
            if(posts.value.length == 0) return;
            now_pages.value = now_pages.value + 1;
            refresh_posts();
        }

        // 用户帖子刷新
        const refresh_posts = () => {
            Loading(1);
            $.ajax({
                url: "https://chat.kkbpro.com/api/user/post/getposts/",
                type: "get",
                data: {
                    writer_id: select_user_id.value,
                    pages: now_pages.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    posts.value = resp;
                    Loading(2);
                },
            });
        };

        // 用户好友刷新
        const refresh_friends = () => {
            Loading(1);
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/getfriends/",
                type: "get",
                data: {
                    pages: friend_now_pages.value,
                    id: select_user_id.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    friends.value = resp;
                    Loading(2);
                },
            });
        }

        // 添加帖子
        const add_post = () => {
            add_post_info.err_msg = "";
            $.ajax({
            url: "https://chat.kkbpro.com/api/root/kk/post/add/",
            type: "post",
            data: {
                user_id: select_user_id.value,
                title: add_post_info.title,
                content: add_post_info.content,
            },
            headers: {
                Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
                if(resp.err_msg == 'success') {
                    add_post_info.title = '';
                    add_post_info.content = '';
                    // 关闭模态框
                    Modal.getInstance("#add_post").hide();
                    // 刷新
                    select_users.value[0].posts_num = select_users.value[0].posts_num + 1;
                    refresh_posts();
                }
                else {
                    add_post_info.err_msg = resp.err_msg;
                }
            },
            error(resp) {
                add_post_info.err_msg = resp.err_msg;
            }
            });
        }

        // 删除帖子
        const remove_post = (post) => {
            $.ajax({
            url: "https://chat.kkbpro.com/api/root/kk/post/remove/",
            type: "post",
            data: {
                id: post.id,
            },
            headers: {
                Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
                if(resp.err_msg == 'success') {
                // 关闭模态框
                Modal.getInstance('#del_confirm_' + post.id).hide();
                // 刷新
                select_users.value[0].posts_num = select_users.value[0].posts_num - 1;
                refresh_posts(); 
                }
            },
            error() {
                
            }
            });
        }

        // 更新帖子
        const update_post = (post) => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/post/update/",
                type: "post",
                data: {
                    id: post.id,
                    title: post.title,
                    content: post.content,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                if(resp.err_msg == 'success') {
                    // 关闭模态框
                    Modal.getInstance("#update_post_" + post.id).hide();
                    modify_post_errmsg.value = '';
                    refresh_posts();
                }
                else {
                    modify_post_errmsg.value = resp.err_msg;
                }
                },
                error(resp) {
                    modify_post_errmsg.value = resp.err_msg;
                }
            });
        }

        let new_uname = ref('');
        let moduname_errmsg = ref('');

        // 修改用户名
        const modify_uname = () => {
            moduname_errmsg.value = '';
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/modify/uname/",
                type: "post",
                data: {
                    id: select_user_id.value,
                    new_uname: new_uname.value,
                },
                headers: {
                Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        // 刷新
                        rootSearchUser();
                        select_users.value[0].uname = new_uname.value;
                        new_uname.value = '';
                    }
                    else {
                        moduname_errmsg.value = resp.err_msg;
                    }
                },
                error(resp) {
                    moduname_errmsg.value = resp.err_msg;
                }
            });
        }

        let new_description = ref('');
        let moddescription_errmsg = ref('');

        // 修改称号
        const modify_description = () => {
            moddescription_errmsg.value = '';
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/modify/description/",
                type: "post",
                data: {
                    id: select_user_id.value,
                    new_description: new_description.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        // 刷新个人信息
                        rootSearchUser();
                        select_users.value[0].description = new_description.value;
                        new_description.value = '';
                    }
                    else {
                        moddescription_errmsg.value = resp.err_msg;
                    }
                },
                error(resp) {
                    moddescription_errmsg.value = resp.err_msg;
                }
            });
        }

        let new_password = ref('');
        let modpassword_errmsg = ref('');

        // 修改密码
        const modify_password = () => {
            modpassword_errmsg.value = '';
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/modify/password/",
                type: "post",
                data: {
                    id: select_user_id.value,
                    new_password: new_password.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        new_password.value = '';
                    }
                    else {
                        modpassword_errmsg.value = resp.err_msg;
                    }
                },
                error(resp) {
                    modpassword_errmsg.value = resp.err_msg;
                }
            });
        }

        // 修改头像
        let modphoto_errmsg = ref('');
        const modify_photo = () => {
            let formFile = document.querySelector('#formFile');
            if(formFile.files[0]) {
                upLoadPicture(formFile.files[0]);
                formFile.value = '';
            }
            else {
                modphoto_errmsg.value = '未选择文件~';
            }
        }

        // 向云端发送图片
        const upLoadPicture = (file) => {
            if(file.size > 30*1024*1024)
            {
            modphoto_errmsg.value = '图片过大~';
            return;
            }
            let formData = new FormData();
            formData.append('id',select_user_id.value);
            formData.append('file',file);
            // OSS 文件上传
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/modify/photo/",
                type: "post",
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                processData: false,
                contentType: false,
                success(resp) {
                    if(resp.err_msg == 'success') {
                        modphoto_errmsg.value = '';
                        // 刷新个人信息
                        rootSearchUser();
                    }
                    else {
                        modphoto_errmsg.value = resp.err_msg;
                    }
                },
                error(resp) {
                    modphoto_errmsg.value = resp.err_msg;
                }
            });
        }

        // 修改模块显示
        const dispaly_control1 = () => {
            let modifyPhotoBlock = document.querySelector('#modifyPhotoBlock');
            modifyPhotoBlock.style.display = '';
        }

        const disappear_control1 = () => {
            let modifyPhotoBlock = document.querySelector('#modifyPhotoBlock');
            modifyPhotoBlock.style.display = 'none';
        }

        const dispaly_control2 = () => {
            let modifyUnameBlock = document.querySelector('#modifyUnameBlock');
            modifyUnameBlock.style.display = '';
        }

        const disappear_control2 = () => {
            let modifyUnameBlock = document.querySelector('#modifyUnameBlock');
            modifyUnameBlock.style.display = 'none';
        }

        const dispaly_control3 = () => {
            let modifyDescriptionBlock = document.querySelector('#modifyDescriptionBlock');
            modifyDescriptionBlock.style.display = '';
        }

        const disappear_control3 = () => {
            let modifyDescriptionBlock = document.querySelector('#modifyDescriptionBlock');
            modifyDescriptionBlock.style.display = 'none';
        }

        const dispaly_control4 = () => {
            let modifyPasswordBlock = document.querySelector('#modifyPasswordBlock');
            modifyPasswordBlock.style.display = '';
        }

        const disappear_control4 = () => {
            let modifyPasswordBlock = document.querySelector('#modifyPasswordBlock');
            modifyPasswordBlock.style.display = 'none';
        }


        // 管理员添加好友
        let add_friend_id = ref('');
        const rootAddFriend = () => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/friend/add/",
                type: "post",
                data: {
                    friend_id: add_friend_id.value,
                    user_id: select_user_id.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        // 刷新
                        add_friend_id.value = '';
                        select_users.value[0].friends_num = select_users.value[0].friends_num + 1;
                        refresh_friends(); 
                    }
                },
                error() {
                    
                }
            });
        }

        // 管理员删除好友
        const rootRemoveFriend = (friend) => {
            $.ajax({
                url: "https://chat.kkbpro.com/api/root/kk/friend/remove/",
                type: "post",
                data: {
                    friend_id: friend.id,
                    user_id: select_user_id.value,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        // 关闭模态框
                        Modal.getInstance('#del_friend_confirm_' + friend.id).hide();
                        // 刷新
                        select_users.value[0].friends_num = select_users.value[0].friends_num - 1;
                        refresh_friends(); 
                    }
                },
                error() {
                    
                }
            });
        }



        return {
            users,
            posts,
            friends,
            add_post_info,
            select_user_id,
            select_users,
            search_now_pages,
            friend_now_pages,
            now_pages,
            search_msg,
            rootSearchUser,
            search_last_pages,
            search_next_pages,
            getDetail,
            last_pages,
            next_pages,
            add_post,
            remove_post,
            update_post,
            dispaly_control1,
            dispaly_control2,
            dispaly_control3,
            dispaly_control4,
            disappear_control1,
            disappear_control2,
            disappear_control3,
            disappear_control4,
            emoji_display,
            emoji_disappear,
            onSelectEmoji,
            modify_post_errmsg,
            modify_uname,
            new_uname,
            moduname_errmsg,
            modify_description,
            new_description,
            moddescription_errmsg,
            new_password,
            modpassword_errmsg,
            modify_password,
            modphoto_errmsg,
            modify_photo,
            add_friend_id,
            rootAddFriend,
            rootRemoveFriend,
            friend_last_pages,
            friend_next_pages,
            authorityInfo,
            rootModifyAuthorityInfo,
            Authority_errmsg,
            Loading,

        }
    },
  
  }
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.kkcol3 {
    /* 高度不随其他列改变 */
    align-self: flex-start;
    background-color: #fcfcfc; 
    margin: 20px 0; 
    margin-right: 8px;
    text-align: left; 
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    /* color: #5e5e5e; */
}

.kkcol7 {
    /* 高度不随其他列改变 */
    align-self: flex-start;
    background-color: #ffffff; 
    margin: 20px 0; 
    margin-left: 7px;
    text-align: center; 
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kks {
    float: right;
    height: 22px;
    width: 100px;
    padding-left: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1.8vh;
    background-color: #D8D9D8;
}

.kkli {
  margin-left: 1px;
  margin-right: 1px;
  padding: 10px 10px; 
  padding-bottom: 5px;
  margin: 0 0;
}


.kkli:hover {
  background-color: #d9d8d8;
}

.kk3{
  text-align: center;
  font-size: 12px;
  color: rgb(254, 81, 81);
}

#kkrootUserShow {
  border-bottom: 1px solid rgb(199, 199, 199);
  margin: 5px 0;
  overflow: hidden;
}

.kkset {
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
  border-bottom: 1px solid #d4d6dc;
}

.kkitem {
  text-align: left; 
  margin-top: 5px;
  border-bottom: 1px solid #d4d6dc;
}

.kkinput {
  height: 24px; 
  font-size: 12px; 
  line-height: 10px;
  margin-bottom: 5px;
}

.kk7
{
  position: relative;
  height: 40px;
  width: 40px;
  text-align: left;
}

.kk9{
  text-align: center;
  color: rgb(254, 81, 81);
  font-size: 13px;
}

.kk12
{
  position: absolute;
  top: -320px;
}

.err {
  color: rgb(254, 81, 81);
  font-size: 12px;
}

</style>