<template>
  <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
      <div style="position: absolute; top: 40%; left: 47%; text-align: center;">
          <div class="spinner-border text-secondary" role="status"></div>
          <div> 加载中... </div>
      </div>
    </div>
  </div>
  <NavBar></NavBar>
  <div class="row">
      <div class="col-2"></div>
      <div class="col-8 kk1">
          <div style="font-size: 13px; margin: 10px 0; color: #4b6b78;">
              <img @click="showTypeChange" src="../assets/转换.png" alt="转换" style="float: left; width: 20px;">
              <span style="margin-left: 80px;"> 管理员kk，欢迎您~  </span>
              <img v-if="show_type == 1" @click="getPostsByKeyWord" src="../assets/管理员.png" alt="管理员" style="float: right; width: 20px;">
              <img v-if="show_type == 2" @click="getCommentsByKeyWord" src="../assets/管理员.png" alt="管理员" style="float: right; width: 20px;">
              <input v-if="show_type == 1" @input="getPostsByKeyWord" v-model="post_search_msg" class="kks" type="text" placeholder="查找帖子~" >
              <input v-if="show_type == 2" @input="getCommentsByKeyWord" v-model="comment_search_msg" class="kks" type="text" placeholder="查找评论~" >
          </div>
          <table class="table table-striped table-hover" v-if="show_type == 1">
            <thead>
                    <tr style="font-size: 14px;">
                    <th> 标题 </th>
                    <th>作者id</th>
                    <th>相关数据</th>
                    <th> 操作 </th>
                    </tr>
            </thead>
            <tbody>
                    <tr v-for="post in posts" :key="post.id">
                    <td>
                        <div style="word-break: break-all; font-weight: bold; color: black; max-width: 250px;">{{ post.title }}</div>
                    </td>
                    <td>
                      <div style="font-size: 13px; color: #5e5e5e; font-weight: bold;"> {{ post.writer_id }} </div>
                    </td>
                    <td>
                        <div style="font-size: 12px; color: #5e5e5e;">帖子id: {{ post.id }}</div>
                        <div style="font-size: 12px; color: #5e5e5e;">创建时间: {{ post.create_time }}</div>
                        <div style="font-size: 12px; color: #5e5e5e;">更新时间: {{ post.modify_time }}</div>
                        <div style="font-size: 12px; color: #5e5e5e;"> 阅读{{ post.read_num }}，点赞{{ post.praises_num }}，评论{{ post.comments_num }} </div>
                    </td>
                    <td>
                        <img src="../assets/修改帖子.png" alt="修改帖子" style="float: left; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#update_post_' + post.id">

                        <!-- Modal update_post-->
                        <div class="modal fade" :id="'update_post_' + post.id" tabindex="-1">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">修改用户帖子~</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="mb-3">
                                <label for="post_title" class="form-label">标题</label>
                                <input v-model="post.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                                </div>
                                <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                                <textarea v-model="post.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                                    placeholder="在此输入正文~"></textarea>
                                </div>
                                <div class="kk7" @mouseover="emoji_display(post.id)" @mouseleave="emoji_disappear(post.id)">
                                <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                                <EmojiPicker :native="true" @select="onSelectEmoji($event, post)" style="display: none;" :id="'EmojiPicker_' + post.id" class="kk12"/>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="err">{{ modify_post_errmsg }}</div>
                                <button type="button" class="btn btn-primary btn-sm" @click="update_post(post)">修改</button>
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        <!-- Modal -->
                        <img src="../assets/删除帖子.png" alt="删除帖子" style="float: right; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_confirm_' + post.id">

                        <!-- 删除帖子确认 Modal -->
                        <div class="modal fade" :id="'del_confirm_' + post.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">删除帖子~</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" style="text-align: left;">
                                确认删除该帖子吗？
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger btn-sm" @click="remove_post(post)">删除</button>
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>
            </tbody>
          </table>
          <div v-if="show_type == 1">
            <div v-if="posts.length == 0 && post_now_pages == 1" class="kk9"> 未找到任何相关帖子~ 
              <div style="margin-bottom: 20px;"></div>
            </div>
            <div v-if="posts.length == 0 && post_now_pages > 1" class="kk9"> 到底啦~ </div>
            <div v-if="posts.length != 0 || post_now_pages != 1" style="float: right; margin-right: 10px;">
              <img @click="post_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
              <span style="font-size: 12px;">&nbsp; {{ post_now_pages }} &nbsp;</span>
              <img @click="post_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
              <div style="margin-bottom: 10px;"></div>
            </div>
          </div>

          <table class="table table-striped table-hover" v-if="show_type == 2">
            <thead>
              <tr style="font-size: 14px;">
                <th> 评论帖子id </th>
                <th> 评论用户id </th>
                <th> 评论内容 </th>
                <th> 评论时间 </th>
                <th> 删除 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="comment in comments" :key="comment.id">
                <td>
                  <div style="font-size: 13px; color: #5e5e5e; font-weight: bold;"> {{ comment.post_id }} </div>
                </td>
                <td>
                  <div style="font-size: 13px; color: #5e5e5e;"> {{ comment.user_id }} </div>
                </td>
                <td>
                  <div style="font-size: 12px; color: #5e5e5e; word-break: break-all; max-width: 400px;"> {{ comment.content }} </div>
                </td>
                <td>
                  <div style="font-size: 12px; color: #5e5e5e;"> {{ comment.send_time }} </div>
                </td>
                <td>
                  <!-- Modal -->
                  <img src="../assets/管理员删除.png" alt="删除评论记录" style="width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_comment_confirm_' + comment.id">

                  <!-- 删除评论确认 Modal -->
                  <div class="modal fade" :id="'del_comment_confirm_' + comment.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel">删除评论~</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="text-align: left;">
                            确认删除该评论吗？
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger btn-sm" @click="remove_comment(comment)">删除</button>
                            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="show_type == 2">
            <div v-if="comments.length == 0 && comment_now_pages == 1" class="kk9"> 未找到任何相关评论~ 
              <div style="margin-bottom: 20px;"></div>
            </div>
            <div v-if="comments.length == 0 && comment_now_pages > 1" class="kk9"> 到底啦~ </div>
            <div v-if="comments.length != 0 || comment_now_pages != 1" style="float: right; margin-right: 10px;">
              <img @click="comment_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
              <span style="font-size: 12px;">&nbsp; {{ comment_now_pages }} &nbsp;</span>
              <img @click="comment_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
              <div style="margin-bottom: 10px;"></div>
            </div>
          </div>

      </div>
  </div>

</template>

<script>
import NavBar from "../components/NavBar.vue";
// import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { ref } from 'vue';
import store from '@/store/user';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';

import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';

export default {
    name: 'KKRootPostBlock',
    components: {
      NavBar,
      EmojiPicker,
  
    },

    setup()
    {
      // Loading...
      const Loading = (type) => {
        let LoadingBlock = document.querySelector('#LoadingBlock');
        if(type == 1) {
          LoadingBlock.style.display = '';
        }
        else {
          LoadingBlock.style.display = 'none';
        }
      }

      onMounted(() => {
        getPostsByKeyWord();
        getCommentsByKeyWord();
      })

      let show_type = ref(1);
      let posts = ref([]);
      let comments = ref([]);
      let post_search_msg = ref('');
      let comment_search_msg = ref('');
      let post_now_pages = ref(1);
      let comment_now_pages = ref(1);

      // 查询转换
      const showTypeChange = () => {
        if(show_type.value == 1) show_type.value = 2;
        else show_type.value = 1;
        getPostsByKeyWord();
        getCommentsByKeyWord();
      }

      // 获取帖子
      const getPostsByKeyWord = () => {
        Loading(1);
        $.ajax({
          url: "https://chat.kkbpro.com/api/root/kk/post/getinfo/",
          type: "get",
          data: {
              pages: post_now_pages.value,
              message: post_search_msg.value,
          },
          headers: {
              Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
              posts.value = resp;
              Loading(2);
          },
          error() {
          }
        });
      }

      // 获取评论
      const getCommentsByKeyWord = () => {
        Loading(1);
        $.ajax({
          url: "https://chat.kkbpro.com/api/root/kk/comment/getinfo/",
          type: "get",
          data: {
              pages: comment_now_pages.value,
              message: comment_search_msg.value,
          },
          headers: {
              Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
              comments.value = resp;
              Loading(2);
          },
          error() {
          }
        });
      }

      // emoji处理
      const emoji_display = (id) => {
          let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
          EmojiPicker.style.display = '';
      }

      const emoji_disappear = (id) => {
        let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
        EmojiPicker.style.display = 'none';
      }

      const onSelectEmoji = (emoji, post) => {
        // 选择表情的回调函数
        post.content += emoji.i;

      }

      // 用户帖子翻页
      const post_last_pages = () => {
        if(post_now_pages.value <= 1) return;
        post_now_pages.value = post_now_pages.value - 1;
        getPostsByKeyWord();
      }

      const post_next_pages = () => {
        if(posts.value.length == 0) return;
        post_now_pages.value = post_now_pages.value + 1;
        getPostsByKeyWord();
      }

      // 评论翻页
      const comment_last_pages = () => {
        if(comment_now_pages.value <= 1) return;
        comment_now_pages.value = comment_now_pages.value - 1;
        getCommentsByKeyWord();
      }

      const comment_next_pages = () => {
        if(comments.value.length == 0) return;
        comment_now_pages.value = comment_now_pages.value + 1;
        getCommentsByKeyWord();
      }

      // 删除帖子
      const remove_post = (post) => {
        $.ajax({
          url: "https://chat.kkbpro.com/api/root/kk/post/remove/",
          type: "post",
          data: {
              id: post.id,
          },
          headers: {
              Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
            // 关闭模态框
            Modal.getInstance('#del_confirm_' + post.id).hide();
            if(resp.err_msg == 'success') {
              // 刷新
              getPostsByKeyWord();
            }
          },
          error() {
              
          }
        });
      }

      // 更新帖子
      let modify_post_errmsg = ref('');
      const update_post = (post) => {
        $.ajax({
            url: "https://chat.kkbpro.com/api/root/kk/post/update/",
            type: "post",
            data: {
                id: post.id,
                title: post.title,
                content: post.content,
            },
            headers: {
                Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
              // 关闭模态框
              Modal.getInstance("#update_post_" + post.id).hide();
              if(resp.err_msg == 'success') {
                  modify_post_errmsg.value = '';
                  getPostsByKeyWord();
              }
              else {
                  modify_post_errmsg.value = resp.err_msg;
              }
            },
            error(resp) {
                modify_post_errmsg.value = resp.err_msg;
            }
        });
      }
      
      // 删除评论
      const remove_comment = (comment) => {
        $.ajax({
            url: "https://chat.kkbpro.com/api/root/kk/comment/remove/",
            type: "post",
            data: {
              id: comment.id,
            },
            headers: {
              Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
              // 关闭模态框
              Modal.getInstance('#del_comment_confirm_' + comment.id).hide();
              if(resp.err_msg == 'success') {
                // 刷新
                getCommentsByKeyWord();
              }
            },
            error() {
            }
        });
      }


      return {
        show_type,
        showTypeChange,
        posts,
        comments,
        post_search_msg,
        comment_search_msg,
        getPostsByKeyWord,
        post_now_pages,
        comment_now_pages,
        emoji_display,
        emoji_disappear,
        onSelectEmoji,
        post_last_pages,
        post_next_pages,
        comment_last_pages,
        comment_next_pages,
        remove_post,
        update_post,
        modify_post_errmsg,
        getCommentsByKeyWord,
        remove_comment,
        Loading,



      }
    },
  
  }
</script>

<style scoped>
img:hover {
  cursor: pointer;
}
.kks {
    float: right;
    height: 22px;
    width: 100px;
    padding-left: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1.8vh;
    background-color: #D8D9D8;
}


.kk1
{
    background-color: #fcfcfc;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kk7
{
  position: relative;
  height: 40px;
  width: 40px;
  text-align: left;
}

.kk9{
  text-align: center;
  color: rgb(254, 81, 81);
  font-size: 13px;
}

.kk12
{
  position: absolute;
  top: -320px;
}

</style>